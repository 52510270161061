import { GetContractRequest, GetContractResponse } from "@/services/get-contract/getContractService";

export default function useGetContract () {
  const getContract = async (req: GetContractRequest) => {
    const result = await fetch(`/api/get-contract`, {
      body: JSON.stringify(req),
      method: "POST",
    });
    const { data } = await result.json();
    const resultJson = data as GetContractResponse;
    return resultJson;
  };
  return getContract;
}
