/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import React, { Suspense, useEffect, useState } from "react";
import { useSearchParams, useRouter } from "next/navigation";

// Components.
import { ColumnTable, RowTable } from "@/components/table/UITableProps";
import UIButton from "@/components/buttons/UIButton";
import UIPrivateLayout from "@/components/layouts/UIPrivateLayout";
import UIRightSidebarLayout from "@/components/layouts/sidebar/UIRightSidebarLayout";
import UITable from "@/components/table/UITable";
import { Box, Grid } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import colors from "@/utils/colorsV2";

// Hooks
import { useProcessesByAgent } from "@/hooks/useProcessesByAgent";
import { Option } from "@/components/inputs/select/UISelectProps";
import { useRequest } from "@/hooks/useRequest";
import { useInfoFiadoContext } from "@/context/infoFiadoContext";
import UIModal from "@/components/modal/UIModal";
import { GetFilesToBeValidatedToSingnatureResponse } from "@/services/get-files-to-be-validated-for-signature/getFilesToBeValidatedForSignatureService";
import { enqueueSnackbar } from "notistack";
import UIPDFScrollViewer from "@/components/pdfviewers/renderPDF/PDFscroll";
import useGetContract from "@/hooks/useGetContract";
import { useCurrentSessionContext } from "@/context/currentSessionContext";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs";
import UIText from "@/components/text/UIText";
import SearchBox from "@/components/SearchBox";
import UILoading from "@/components/loading/UILoading";

const columnsTable: ColumnTable[] = [
  { id: "idSolicitud", label: "ID solicitud ASI" },
  { id: "nombre", label: "Cliente" },
  { id: "rfc", label: "RFC" },
  { id: "estatus", label: "Estatus" },
  { id: "fechaAlta", label: "Fecha de inicio de alta" },
  { id: "empresa", label: "Aseguradora" },
  { id: "actions", label: "Acciones" },
];

function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [messageLoading, setMessageLoading] = useState("Loading...");
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [tempSearch, setTempSearch] = useState("");
  const [request, setRequests] = useState<RowTable[]>([]);
  const { session } = useCurrentSessionContext();
  const [agent, setAgent] = useState<string>(session?.user?.login ?? "");
  const [filterData, setFilterData] = useState<RowTable[]>([]);
  const [filterOptions, setFilterOptions] = useState<Option[]>([]);
  const [company, setCompany] = useState<string>("");
  const [next, setNext] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [idContract, setIdContract] = useState<string>("");

  const searchParams = useSearchParams();
  const searchParam = searchParams.get("search");

  useEffect(() => {
    if (searchParam) {
      setTempSearch(searchParam);
      setSearch(searchParam);
    } else {
      setTempSearch("");
      setSearch("");
    }
  }, [searchParam]);

  useEffect(() => {
    if (!session) {
      return;
    }

    if (session.user?.login === undefined || session.user?.login === '') {
      return;
    }

    if (agent === session.user.login) {
      return;
    }

    setAgent(session.user.login);
  }, [session]);

  useEffect(() => {
    let dataFiltered = request;
    dataFiltered?.sort((a, b) => {
      const dateA = new Date(a.fechaAlta);
      const dateB = new Date(b.fechaAlta);
      return dateB.getTime() - dateA.getTime();
    });

    if (filter !== "Todos") {
      dataFiltered = dataFiltered.filter((item) => item.estatus === filter);
    }

    if (search !== "") {
      dataFiltered = dataFiltered.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    setFilterData(dataFiltered);
  }, [filter, search, request]);

  const processByAgent = useProcessesByAgent();
  useEffect(() => {
    if (!agent) return;

    const abortController = new AbortController();
    const signal = abortController.signal;

    setIsLoading(true);
    setMessageLoading("Buscando solicitudes...");

    processByAgent({ id: agent }, signal)
      .then((response) => {
        setFilter("Todos");
        const withStatus = response && response.filter((item) => item.estatus || item.idEstatus);
        setRequests(withStatus);
        getOptions(withStatus);
      })
      .catch((error) => {
        console.log("error", error);
        if (error.name !== "AbortError") {
          enqueueSnackbar(error.message, {
            variant: "error",
            preventDuplicate: true,
          });
        }
      })
      .finally(() => setIsLoading(false));

    return () => {
      abortController.abort(); // Cancela la solicitud anterior 
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agent]);

  const getOptions = (data: RowTable[]) => {
    const uniqueStatuses = Array.from(
      new Set(data.map((item) => item.estatus?.toString()))
    );
    const filterOptions = uniqueStatuses.map((value, index) => ({
      value: value,
      id: `${index}`,
    }));
    setFilterOptions([{ value: "Todos", id: "0" }, ...filterOptions]);
  };

  const { getInfoFiado, getFilesToSign } = useRequest();
  const { data, setData, idProcessFiado, setIdProcessFiado } =
    useInfoFiadoContext();

  const editHandler = async (row: RowTable) => {
    setIsLoading(true);
    setMessageLoading("Buscando solicitud...");
    setCompany(row.empresa.toString());

    const id = row.idSolicitud.toString();
    getInfoFiado(id)
      .then((response) => {
        setMessageLoading("Solicitud encontrada");
        setIdProcessFiado(id);
        setData(response);
        setNext(true);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (data !== undefined && next) {
      stepInProcess(idProcessFiado);
    }
  }, [data, next]);

  const stepInProcess = (id: string) => {
    push(`/create/tax-situation?idProcess=${id}&company=${company}`);
  };

  const viewHandler = async (row: RowTable) => {
    setIdContract(row.idSolicitud.toString());
  };
  const getContract = useGetContract();
  const paseTabs = async (
    data: GetFilesToBeValidatedToSingnatureResponse,
    idProcess: string
  ) => {
    const filteredData = data.filter((doc) => !doc.error || doc.error === "");
    return Promise.all(
      filteredData.map(async (doc) => {
        const contract = await getContract({ idProcess, fileName: doc.nombre });
        const url = `data:application/pdf;base64,${contract.contenidoBase64}`;
        return {
          label: doc.empresa,
          children: (
            <Grid item xs={12} marginY={2}>
              <Grid
                container
                spacing={0}
                justifyContent={"center"}
                height={"auto"}
              >
                <Grid
                  item
                  xs={9}
                  display="flex"
                  justifyContent={"center"}
                  height="100%"
                >
                  <UIPDFScrollViewer
                    scale={1}
                    url={url}
                    name={"Contrato solicitud"}
                    variant="h6"
                    clickable={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          ),
        };
      })
    );
  };

  useEffect(() => {
    if (idContract === "") return;
    setOpenModal(true);
  }, [idContract]);

  const { push } = useRouter();

  const closeModal = () => {
    setOpenModal(false);
  };

  const breadcrumbsOptions = [
    {
      icon: <HomeIcon sx={{ fontSize: "20px", color: "#566680" }} />,
    },
    {
      label: "Mis solicitudes",
    },
  ];

  const handleOnClickSearch = () => {
    setSearch(tempSearch);
  };

  return (
    <UIPrivateLayout>
      <UIRightSidebarLayout
        isLoading={isLoading}
        messageLoading={messageLoading}
        open={true}
        drawerWidth={240}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100vh",
            padding: "32px",
            gap: "32px",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Breadcrumbs options={breadcrumbsOptions} />
          </Box>
          <Box
            sx={{
              border: `1px solid ${colors.grayLM200}`,
              borderRadius: "12px",
              backgroundColor: "white",
              position: "relative",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                padding: "20px",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <UIText
                  text={"Clientes"}
                  size="text-lg"
                  color="blue"
                  weight="semibold"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <SearchBox
                  label="Buscar"
                  value={tempSearch}
                  onChange={setTempSearch}
                  onClickSearch={handleOnClickSearch}
                />
                <UIButton
                  sx={{ marginLeft: "80px" }}
                  label="Nuevo cliente"
                  color="orange"
                  size="full"
                  startIcon={<PersonAddIcon />}
                  onClick={() => push("/registration")}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                position: "relative",
                height: "100%",
                flexDirection: "row",
              }}
            >
              <UITable
                columns={columnsTable}
                rows={filterData}
                editHandler={editHandler}
                viewHandler={viewHandler}
              />
            </Box>
          </Box>
          <UIModal
            open={openModal}
            onClose={closeModal}
            logo="/assets/icons/face.svg"
            body={<Grid container direction={'row'} gap={2}>
              <Grid item xs={12}>
                <UIText text={"Puedes ver la documentación contractual en caso de requerir una corrección y/o envío de firma digital"} size="text-md" color="blue" />
              </Grid>
              <Grid xs={12}>
                <UIButton label="Ver documentación" color="orange" onClick={() => { push(`/sing/cosignal?idProcess=${idContract}`); }} fullWidth />
              </Grid>
               {/* <Grid xs={5}>
                <UIButton label="Subir contrato físico" color="secondary" onClick={() => { push(`/sing/load-signature?idProcess=${idContract}`); }} />
              </Grid>  */}
            </Grid>}
          />
        </Box>
      </UIRightSidebarLayout>
    </UIPrivateLayout>
  );
}

const PageWrapper = () => (
  <Suspense fallback={<UILoading />}>
    <Home />
  </Suspense>
);

export default PageWrapper;
