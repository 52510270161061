import React from "react";

function UISmallArrowIcon() {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.166 10h11.667m0 0L9.999 4.165m5.834 5.833l-5.834 5.834"
        stroke="#2E3C6F"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UISmallArrowIcon
